import fetch from 'node-fetch';
import { Status, User, UserPartial } from '../types/user';
import { APIUser } from '../types/user';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getAdminUsers = async (): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/config/user/_admin`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

// url: '/accountUserTestID/user/addaccountUserId'
export const getUser = async (id: string, accountId?: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/config${accountId ? `/${accountId}` : ''}/user/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUserByCognitoId = async (cognitoId: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/config/user/${cognitoId}`;
  const response = await fetch(url);
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUser = async (newUser: boolean, userData: UserPartial, accountId: string, id: string): Promise<number> => {
  const method = newUser ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/config/${accountId}/user/${id}`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const resendTemporaryPassword = async (accountId: string, userId: string) => {
  const method = 'PATCH';
  const requestOptions = {
    method: method,
    headers: { Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/config/${accountId}/user/${userId}/password`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUserAdmin = async (userData: UserPartial): Promise<number> => {
  const method = 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/config/user`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const inviteScribblesAdmin = async (email: string) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ email: email, isScribblesAdmin: true }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/config/user`, requestOptions);
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else if (response.status === 405) {
    return Promise.reject('User already exists');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
