import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlusSquare, FaSave, FaMinusSquare } from 'react-icons/fa';
import { KeyValue, Mapping, DynamicTableProps } from '../types/mapping/';

const DynamicTable: React.FC<DynamicTableProps> = ({ mapping, onSave, onCancel, isEditable }) => {
  const [mappingName, setMappingName] = useState(mapping.mappingName);
  const [rows, setRows] = useState<KeyValue[]>(mapping.data);
  const [saveEnabled, setSaveEnabled] = useState(false);

  useEffect(() => {
    setMappingName(mapping.mappingName);
    setRows(mapping.data);
  }, [mapping]);

  useEffect(() => {
    const mappingNameEntered = mappingName.trim() !== '';
    const keysEntered = rows.some((row) => row.key.trim() !== '');
    setSaveEnabled(mappingNameEntered && keysEntered);
  }, [mappingName, rows]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number, field: 'key' | 'value') => {
    const { value } = e.target;
    const updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      [field]: value,
    };
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    const newItem: KeyValue = {
      key: '',
      value: '',
    };
    setRows([...rows, newItem]);
  };

  const handleRemoveRow = (idx: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(idx, 1);
    setRows(updatedRows);
  };

  const handleSave = () => {
    onSave({ mappingName: mappingName.toUpperCase().replace(/\s+/g, '_'), data: rows });
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleMappingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Convert all characters to uppercase and replace spaces with underscores
    const formattedMappingName = value
      .toUpperCase()
      .replace(/\s+/g, '_')
      .replace(/[^A-Z0-9_]/g, '');
    setMappingName(formattedMappingName);
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="mappingName">Mapping Name:</label>
        <input
          type="text"
          id="mappingName"
          value={mappingName}
          onChange={handleMappingNameChange}
          className="form-control"
          placeholder="Enter Mapping Name"
          disabled={!isEditable}
        />
      </div>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th className="text-center"> Key </th>
            <th className="text-center"> Value </th>
            <th className="text-center"> Remove </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item, idx) => (
            <tr key={idx}>
              <td>
                <input type="text" name="key" value={item.key} onChange={(e) => handleChange(e, idx, 'key')} className="form-control" />
              </td>
              <td>
                <input type="text" name="value" value={item.value} onChange={(e) => handleChange(e, idx, 'value')} className="form-control" />
              </td>
              <td>
                <button className="btn btn-outline-danger btn-sm" onClick={() => handleRemoveRow(idx)} aria-label="Remove Row">
                  <FaTrash size={25} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow} className="btn btn-primary mr-2">
        <FaPlusSquare size={25} />
        Add Row
      </button>
      <button onClick={handleSave} className="btn btn-success" disabled={!saveEnabled}>
        <FaSave size={25} />
        Save
      </button>
      <button onClick={handleCancel} className="btn btn-secondary ml-2">
        <FaMinusSquare size={25} />
        Cancel
      </button>
    </div>
  );
};

export default DynamicTable;
