import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getUserProfile } from '../api/userprofile';
import { Loading } from '../Loading';
import { UserProfile } from '../users/userprofile';

interface UserProvider {
  profile: UserProfile;
}

export const UserContext = createContext<UserProvider | undefined>(undefined);

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [profile, setProfile] = useState<UserProfile>();
  const [initializing, setInitializing] = useState<boolean>(false);

  const fetchProfile = async () => {
    setInitializing(true);
    const userProfileFromAPI = await getUserProfile();

    setProfile(userProfileFromAPI);
    setInitializing(false);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  if (initializing) {
    return <Loading />;
  }

  return profile ? <UserContext.Provider value={{ profile }}>{children}</UserContext.Provider> : <Loading />;
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
