// MappingList.tsx
import React, { useState } from 'react';
import { FaPencilAlt, FaTrash, FaPlusSquare } from 'react-icons/fa';

interface Mapping {
  mappingName: string;
}

interface Props {
  mappings: Mapping[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  onAdd: () => void;
}

const MappingList: React.FC<Props> = ({ mappings, onEdit, onDelete, onAdd }) => {
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const confirmDelete = (index: number) => {
    setDeleteIndex(index);
  };

  const cancelDelete = () => {
    setDeleteIndex(null);
  };

  const handleDelete = () => {
    if (deleteIndex !== null) {
      onDelete(deleteIndex);
      setDeleteIndex(null);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Mapping Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {mappings.map((mapping, index) => (
            <tr key={index}>
              <td>{mapping.mappingName}</td>
              <td>
                <button onClick={() => onEdit(index)} className="btn btn-primary mr-2">
                  <FaPencilAlt size={25} aria-label="Edit" />
                </button>
                <button onClick={() => confirmDelete(index)} className="btn btn-danger mr-2">
                  <FaTrash size={25} aria-label="Delete" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>
              <button onClick={onAdd} className="btn btn-success">
                <FaPlusSquare size={25} />
                Add Mapping
              </button>
            </td>
          </tr>
        </tfoot>
      </table>

      {/* Confirmation Modal */}
      {deleteIndex !== null && (
        <div className="modalBackdrop">
          <div className="modalContent">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete this mapping?</p>
            <div>
              <button onClick={cancelDelete} className="btn btn-secondary">
                Cancel
              </button>
              <button onClick={handleDelete} className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MappingList;
