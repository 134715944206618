import React from 'react';
import UserList from '../users/userlist';
import UserDetail from '../users/userdetail/index';
import UserProfilePage from '../users/userprofile';
import FailedLogin from '../auth/failedlogin';
import MappingEditor from '../mapping/MappingEditor';
import { Route } from 'react-router';
import Config from '../config';

export const Routes = () => {
  return (
    <>
      <Route path="/" exact component={(props: string) => <Config />} />
      <Route path="/adminusers" exact component={(props: string) => <UserList />} />
      <Route path="/userdetail/:id?" component={(props: string) => <UserDetail />} />
      <Route path="/userprofile" component={UserProfilePage} />
      <Route path="/mappingeditor" component={MappingEditor} />
      <Route path="/failedlogin" component={() => <FailedLogin message="You must be invited to join the Archive" />} />
    </>
  );
};
