import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCog, ImUsers } from 'react-icons/im';
import { RiUserFill } from 'react-icons/ri';
import { FaMap } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import styles from '../styles/configui.module.css';
import { useUIContext } from '../contexts/UIContext';
import birdHead from './bird-logo-head.png';

interface NavigationProps {
  logout: () => void;
}

const SideNav = ({ logout }: NavigationProps) => {
  const location = useLocation();
  const { sideBarOpen, toggleSidebar } = useUIContext();

  const navItems = (
    <>
      <div className={styles.navMenuItemContainer}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/'}>
            <ImCog color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Config Manager</div>
          </NavLink>
        </NavItem>
        <div
          style={{ display: location.pathname === '/' ? 'block' : 'none' }}
          className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
        ></div>
      </div>
      <div className={styles.navMenuItemContainer}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/adminusers'}>
            <ImUsers color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Users</div>
          </NavLink>
        </NavItem>
        <div
          style={{ display: location.pathname === '/adminusers' ? 'block' : 'none' }}
          className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
        ></div>
      </div>
      <div className={styles.navMenuItemContainer}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/mappingeditor'}>
            <FaMap color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Mappings</div>
          </NavLink>
        </NavItem>
        <div
          style={{ display: location.pathname === '/mappingeditor' ? 'block' : 'none' }}
          className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
        ></div>
      </div>
    </>
  );

  return (
    <div style={{ width: sideBarOpen ? '13em' : '3em' }} className={styles.sideNav}>
      <div className={styles.navListContainer}>
        <div className={styles.scribLogo}>
          <img src={birdHead} style={{ height: '45px', top: 0, left: 0, transform: 'scaleX(-1)' }} alt="" />
        </div>
        <Nav vertical className="list-unstyled pb-3">
          {navItems}
        </Nav>
      </div>
      <div className={styles.navBarBottom}>
        <div className={styles.navMenuItemContainer}>
          <div className={styles.navMenuItem}>
            <GiHamburgerMenu color="#ceeaf4" size={25} style={{ minWidth: '1.5em' }} className={styles.hamburgerIcon} onClick={toggleSidebar} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Show/Hide Drawer</div>
          </div>
        </div>
        {
          <div className={styles.menuNavLinks}>
            <UncontrolledDropdown direction="up" nav inNavbar className={styles.dropdownToggle}>
              <DropdownToggle
                drop={'up'}
                nav
                style={{ color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '0px', maxWidth: '100%' }}
              >
                <RiUserFill color="#ceeaf4" size={25} className={styles.hamburgerIcon} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Profile</div>
              </DropdownToggle>
              <DropdownMenu style={{ boxShadow: '1px 2px 7px 1px rgb(0 0 0 / 13%)' }}>
                <NavLink className={styles.link} tag={Link} to={'/userprofile'}>
                  <DropdownItem>Profile</DropdownItem>
                </NavLink>
                <DropdownItem divider />
                <DropdownItem onClick={logout}> Log Out </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        }
      </div>
    </div>
  );
};

export default SideNav;
