import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from '../styles/configui.module.css';

interface failedLoginProps {
  message: string;
}

const FailedLogin = ({ message }: failedLoginProps) => {
  return (
    <div>
      <Modal isOpen={true} fade={false} backdropClassName={styles.modalBackdrop}>
        <ModalHeader>Login Failed</ModalHeader>
        <ModalBody>{message}</ModalBody>
      </Modal>
    </div>
  );
};

export default FailedLogin;
