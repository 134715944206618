import React, { useState, useEffect } from 'react';
import MappingList from './MappingList';
import DynamicTable from './DynamicTable';
import { Mapping, KeyValue } from '../types/mapping/';
import { fetchMappings, saveMappings } from '../api/config';
import { toast } from 'react-toastify';

const MappingEditor: React.FC = () => {
  const [mappings, setMappings] = useState<Mapping[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const transformedMappings = await fetchMappings();
        setMappings(transformedMappings);
      } catch (error) {
        toast.error('Unable to load mappings');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [mappings]);

  const handleAddMapping = () => {
    const newMapping: Mapping = { mappingName: '', data: [] }; // Create a new empty mapping
    setMappings([...mappings, newMapping]); // Add the new mapping to the mappings array
    setEditingIndex(mappings.length); // Set the editing index to the new mapping index
    setAddingNew(true);
  };

  const handleEditMapping = (index: number) => {
    setEditingIndex(index); // Set the editing index to the selected mapping index
    setAddingNew(false);
  };

  const handleDeleteMapping = (index: number) => {
    const updatedMappings = [...mappings];
    updatedMappings.splice(index, 1);
    setMappings(updatedMappings);
    saveMappings(mappings);
  };

  const handleSaveMapping = (mapping: Mapping) => {
    const updatedMappings = [...mappings];
    updatedMappings[editingIndex!] = mapping; // Update the mapping at the editing index
    setMappings(updatedMappings);
    console.log(mappings);
    saveMappings(mappings);
    setEditingIndex(null); // Reset the editing index
    setAddingNew(false);
  };

  const handleCancelMapping = () => {
    if (addingNew) {
      // if adding a new mapping and cancel was pressing, remove the new mapping from mappings array
      const updatedMappings = [...mappings];
      updatedMappings.pop(); // remove last added mapping
      setMappings(updatedMappings);
    }
    setEditingIndex(null);
    setAddingNew(false);
  };

  return (
    <div>
      {editingIndex !== null ? (
        <DynamicTable
          mapping={mappings[editingIndex!] || { mappingName: '', data: [] }}
          onSave={handleSaveMapping}
          onCancel={handleCancelMapping}
          isEditable={addingNew}
        />
      ) : (
        <MappingList mappings={mappings} onEdit={handleEditMapping} onDelete={handleDeleteMapping} onAdd={handleAddMapping} />
      )}
    </div>
  );
};

export default MappingEditor;
