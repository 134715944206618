import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import styles from '../styles/configui.module.css';
import { exportConfig, fetchSiteList, importConfig } from '../api/config';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

const Config = () => {
  const [product, setProduct] = useState<string>();
  const [site, setSite] = useState<string>();
  const [siteList, setSiteList] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>();
  const [exported, setExported] = useState<boolean>(false);

  const updateSiteList = async () => {
    setSiteList([]);
    setSite(undefined);
    setSelectedKey(undefined);
    if (!product) {
      return;
    }
    const sites = await toast.promise(fetchSiteList(product), {
      error: 'Unable to load site list',
    });
    setSiteList(sites);
  };

  useEffect(() => {
    updateSiteList();
  }, [product]);

  useEffect(() => {
    setSelectedKey(undefined);
  }, [site]);

  const keyList = useMemo(() => {
    let keyList = [
      { display: 'Site', value: 'site' },
      { display: 'Application', value: 'application' },
      { display: 'Grab Criteria', value: 'grabcriteria' },
      { display: 'Google', value: 'google' },
      { display: 'Azure', value: 'azure' },
      { display: 'Text', value: 'text' },
      { display: 'Email', value: 'email' },
    ];
    if (product === 'choice') {
      keyList = keyList.concat([
        { display: 'Active Directory', value: 'activeDirectory' },
        { display: 'Color', value: 'color' },
        { display: 'Export Fields', value: 'exportFields' },
        { display: 'PDF Form List', value: 'pdfFormList' },
        { display: 'Questions', value: 'questions' },
        { display: 'Question Groups', value: 'questiongroups' },
      ]);
    }

    if (product === 'order') {
      keyList = keyList.concat([
        { display: 'Amazon LPA', value: 'amazon_lpa' },
        { display: 'Transfer', value: 'transfer' },
        { display: 'Schools', value: 'schools' },
        { display: 'Student Lookup', value: 'studentLookup' },
        { display: 'Document Packages', value: 'documentpackages' },
        { display: 'Legacy Properties', value: 'legacyproperties' },
        { display: 'Purge', value: 'purge' },
        { display: 'Purge Criteria', value: 'purgecriteria' },
      ]);
    }
    return keyList;
  }, [product]);

  const doExport = async () => {
    if (!product || !site || !selectedKey) return;
    await toast.promise(exportConfig(product, site, selectedKey), {
      success: 'Download complete',
      pending: 'Fetching configuration',
      error: 'Unable to fetch configuration',
    });
    setExported(true);
  };

  useEffect(() => {
    setExported(false);
  }, [product, site, selectedKey]);

  const onDrop = async (acceptedFiles: File[]) => {
    console.log('on drop', acceptedFiles);
    await fileUploaded(acceptedFiles);
  };

  const fileUploaded = async (files: File[]) => {
    if (!product || !site || !selectedKey) return;
    if (!files || files.length === 0) {
      toast.error('Invalid file type, please upload a json file');
      return;
    }

    if (files.length > 1) {
      toast.error('Please select only 1 file');
      return;
    }

    console.log('doing the thing');
    await toast.promise(importConfig(product, site, selectedKey, files[0]), {
      success: 'Configuration updated',
      pending: 'Posting JSON',
      error: 'Unable to update configuration',
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/JSON',
    disabled: !site || !product || !selectedKey || !exported,
  });

  return (
    <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
      <CardBody className={styles.searchBarHeader}>
        <div>
          <h5 className={styles.searchBarTitle}>Config Utils</h5>
        </div>
      </CardBody>
      <CardBody className={styles.searchBarBody}>
        <Form>
          <Row form>
            <Col md="3">
              <FormGroup row>
                <Col sm="3">
                  <label className="col-form-label">Product:</label>
                </Col>
                <Col sm="9">
                  <Input value={product} type="select" name="product" id="product" onChange={(e) => setProduct(e.target.value)} required>
                    <option value={undefined}></option>
                    <option value="choice">Choice</option>
                    <option value="order">Order</option>
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup row>
                <Col sm="3">
                  <label className="col-form-label">Site:</label>
                </Col>
                <Col sm="9">
                  <Input
                    value={site}
                    type="select"
                    name="site"
                    id="site"
                    onChange={(e) => setSite(e.target.value)}
                    disabled={!product || siteList.length === 0}
                    required
                  >
                    <option value={undefined}></option>
                    {siteList.map((site) => {
                      return (
                        <option key={`site_${site}`} value={site}>
                          {site}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup row>
                <Col sm="3">
                  <label className="col-form-label">Type:</label>
                </Col>
                <Col sm="9">
                  <Input
                    value={selectedKey || ''}
                    type="select"
                    name="key"
                    id="key"
                    onChange={(e) => setSelectedKey(e.target.value)}
                    disabled={!product || !site}
                    required
                  >
                    <option value=""></option>
                    {keyList.map(({ display, value }) => {
                      return (
                        <option key={`configType_${value}`} value={value}>
                          {display}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col md="3">
              <Row>
                <Col md="3">
                  <Button className={styles.scrbBtnGreen} disabled={!product || !site || !selectedKey} onClick={doExport}>
                    Export
                  </Button>
                </Col>
                <Col md="9">
                  <div {...getRootProps()}>
                    <Button
                      className={styles.scrbBtnBlue}
                      block
                      onClick={(event) => event.preventDefault()}
                      disabled={!product || !site || !selectedKey || !exported}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? <>Drop the file here ...</> : <>Import</>}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Config;
