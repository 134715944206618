import React, { FormEvent, useState } from 'react';
import { Modal, ModalHeader, Form, ModalBody, Row, Col, FormGroup, Label, Input, FormFeedback, ModalFooter, Button } from 'reactstrap';
import styles from '../../styles/configui.module.css';

interface UserInviteModalProps {
  modal: boolean;
  toggle: () => void;
  inviteUser: (email: string) => void;
  isSubmitting: boolean;
}

const UserInviteModal = ({ modal, toggle, inviteUser, isSubmitting }: UserInviteModalProps) => {
  const [invitedUser, setInvitedUser] = useState({
    invitedUserEmail: '',
  });
  const [touched, setTouched] = useState({
    email: false,
    roleId: false,
    permissions: false,
  });
  const addToInvitedUser = (key: string, value: any) => {
    setInvitedUser((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (email: string) => {
    const errors = {
      email: '',
    };

    if (touched.email && /\S+@\S+\.\S+/.test(email.trim()) === false) {
      errors.email = 'Email provided is not a valid email';
    }

    return errors;
  };

  const formErrors = validate(invitedUser.invitedUserEmail);

  const inviteUserHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formErrors.email) {
      inviteUser(invitedUser.invitedUserEmail);
    }
  };

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader toggle={toggle}>Invite User</ModalHeader>
        <Form onSubmit={inviteUserHandler}>
          <ModalBody>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    onBlur={() => handleBlur('email')}
                    required
                    invalid={formErrors.email !== ''}
                    onChange={(e) => {
                      addToInvitedUser('invitedUserEmail', e.target.value);
                    }}
                  />
                  <FormFeedback>{formErrors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button className={styles.scrbBtnOrange} disabled={isSubmitting} type="submit">
              Send Invitation
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default UserInviteModal;
