import React, { useState, useEffect, FormEvent, useMemo, useCallback, useRef } from 'react';
import { Col, CardBody, Row, Button, Form, FormGroup, Label, Input, Alert, CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import { useParams } from 'react-router';
import { getUser, updateUserAdmin } from '../../api/users';
import styles from '../../styles/configui.module.css';
import { Status } from '../../types/user';
import { useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/bootstrap.css';
import { toast } from 'react-toastify';
import { LoadingComponent } from '../../Loading';

interface UserDetail {
  id: string;
  cognitoIds: string[];
  familyName: string;
  givenName: string;
  email: string;
  phone: string;
  status: Status;
  thirdPartyAuth: boolean;
}
interface UserDetailParams {
  id?: string;
}

const UserDetail = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<UserDetailParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const cognitoIds: Array<string> = [];
  const userStatus: Status = 'ACTIVE';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [userDetail, setUserDetail] = useState<UserDetail>({
    id: '',
    cognitoIds: cognitoIds,
    familyName: '',
    givenName: '',
    email: '',
    phone: '',
    status: userStatus,
    thirdPartyAuth: false,
  });
  const initialStatusRef = useRef<Status | undefined>();

  const setInitialState = async () => {
    if (id && id !== '') {
      const currentUser = await getUser(id);
      setUserDetail(() => {
        return {
          ...userDetail,
          id: currentUser.id,
          accountMembership: currentUser.accountMembership,
          cognitoIds: currentUser.cognitoIds,
          familyName: currentUser.familyName,
          givenName: currentUser.givenName,
          email: currentUser.email,
          phone: currentUser.phone,
          status: currentUser.status,
          thirdPartyAuth: currentUser.thirdPartyAuth,
        };
      });
      initialStatusRef.current = currentUser.status;
      setLoading(false);
    }
  };

  useEffect(() => {
    setInitialState();
  }, []);

  const addToPartialUser = (key: string, value: any) => {
    setUserDetail((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  const updateAccountStatus = useCallback(
    (newStatus: string) => {
      setUserDetail((userDetail) => {
        return { ...userDetail, status: newStatus as Status };
      });
      return;
    },
    [userDetail],
  );

  const _saveUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await toast.promise(updateUserAdmin(userDetail), {
      pending: 'Updating user',
      success: 'User updated',
      error: 'Unable to update user',
    });
    history.push('/adminusers');
    return;
  };

  const saveUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    try {
      await _saveUserHandler(event);
    } catch (e) {
      toast.error('Unable to save this user, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LoadingComponent isLoading={loading}>
      <div className={styles.detailContainer}>
        <CardBody className={styles.searchBarHeader}>
          <h5 className={styles.searchBarTitle}>User Detail</h5>
        </CardBody>
        <CardBody>
          <Form
            onSubmit={async (event) => {
              await saveUserHandler(event);
            }}
          >
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="name">First Name</Label>
                  <Input value={userDetail.givenName} type="text" name="givenName" id="givenName" readOnly />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="name">Last Name</Label>
                  <Input value={userDetail.familyName} type="text" name="name" id="name" readOnly />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input value={userDetail.email} type="text" name="email" id="email" readOnly />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Input value={userDetail.phone} type="text" name="phone" id="phone" readOnly />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Input value={userDetail.status} type="select" name="status" id="status" onChange={(e) => updateAccountStatus(e.target.value)} required>
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Col md={6} style={{ display: userDetail.id == '' ? 'flex' : 'none' }}>
              <FormGroup>
                <Row>
                  <ListGroupItem style={{ border: '1px solid #fff' }}>
                    <CustomInput
                      checked={userDetail.thirdPartyAuth}
                      onChange={(e) => {
                        if (userDetail.thirdPartyAuth) {
                          addToPartialUser('thirdPartyAuth', false);
                        } else {
                          addToPartialUser('thirdPartyAuth', true);
                        }
                      }}
                      type="checkbox"
                      label="Logging in with 3rd Party/Cognito"
                      id="thirdPartyLogin"
                    />
                  </ListGroupItem>
                </Row>
              </FormGroup>
            </Col>
            <div className={styles.detailBtnContainer}>
              <Button
                className={styles.scrbBtnBlueMargin}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button className={styles.scrbBtnOrange} disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        </CardBody>
      </div>
    </LoadingComponent>
  );
};

export default UserDetail;
