import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Main from './main/main';
import AuthService from './auth/authservice';
import { AuthProvider } from './auth/authprovider';
import { Loading } from './Loading';
import Errorboundary from './errorboundary';

function App() {
  const [authService, setAuthService] = useState<AuthService>();
  useEffect(() => {
    console.log('auth service useeffect');
    const getAuthService = async () => {
      const service = await AuthService.getInstance();
      setAuthService(() => {
        return service;
      });
    };
    getAuthService();
  }, []);

  if (authService) {
    return (
      <BrowserRouter>
        <AuthProvider authProvider={authService}>
          <div className="App">
            <Errorboundary>
              <Main />
            </Errorboundary>
          </div>
        </AuthProvider>
      </BrowserRouter>
    );
  } else {
    return <Loading></Loading>;
  }
}

export default App;
