import fetch from 'node-fetch';
import { UserManager, Log, UserManagerSettings } from 'oidc-client';
import { getBaseApiUrl } from '../api/helpers';

export default class AuthService {
  UserManager;
  redirectUri?: string;
  authority?: string;
  client_id?: string;

  constructor(config: UserManagerSettings) {
    Log.logger = console;
    //Log.level = Log.DEBUG;
    this.UserManager = new UserManager({ ...config });

    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });
    console.log(`new AuthService created... ${JSON.stringify(config, null, 2)}`);
    this.redirectUri = config.redirect_uri;
    this.authority = config.authority;
    this.client_id = config.client_id;
    this.UserManager.events.addAccessTokenExpired(() => {
      this.logout();
      //this.signinRedirect();
    });
  }

  static _instance: AuthService;

  static getInstance = async (): Promise<AuthService> => {
    if (!this._instance) {
      const response = await (await fetch(`${getBaseApiUrl()}/v1/api/config/oidc_configuration`)).json();
      console.log(`getInstance got response: ${JSON.stringify(response, null, 2)}`);
      if (process.env.NODE_ENV === 'development') {
        response.redirect_uri = 'http://localhost:3000/signin-callback.html';
        response.post_logout_redirect_uri = 'http://localhost:3000';
      }
      this._instance = new AuthService({ ...response });
    }
    return this._instance;
    //get oidc config
    //return instance
  };

  signinRedirectCallback = async () => {
    return this.UserManager.signinRedirectCallback();
  };

  getUser = async (accountId?: string) => {
    const user = await this.UserManager.getUser();
    if (!user && (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_SANDBOX_ENV === 'SANDBOX')) {
      console.log('getUser redirecting to signin');
      console.log(user);
      console.log(process.env.NODE_ENV);
      return await this.UserManager.signinRedirect();
    }
    return user;
  };

  getAuthToken = async () => {
    if (window.location.href.indexOf('embedded_') >= 0 && localStorage.getItem('integration_token')) {
      const token = localStorage.getItem('integration_token');
      if (token) {
        return token;
      }
    }
    const user = await this.UserManager.getUser();
    if (!user && (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_SANDBOX_ENV === 'SANDBOX')) {
      return await this.signinRedirect();
    } else if (user) {
      return user.access_token;
    }
  };

  getUserCognitoUsername = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      console.log('getUserCognitoUsername redirecting to signin');
      return await this.signinRedirect();
    }
    const cognitoUsername = user.profile['cognito:username'];
    return cognitoUsername;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = async () => {
    localStorage.setItem('prevLocation', window.location.href);
    return this.UserManager.signinRedirect({
      redirect_uri: this.redirectUri,
    });
  };

  isAuthenticated = () => {
    const session = sessionStorage.getItem(`oidc.user:${this.authority}:${this.client_id}`) || '';
    if (!session) return false;
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${this.authority}:${this.client_id}`) || '');
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  createSigninRequest = async () => {
    return this.UserManager.createSigninRequest();
  };

  logout = async () => {
    await this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    return this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = async () => {
    await this.UserManager.signoutRedirectCallback();
    localStorage.clear();
    return this.UserManager.clearStaleState();
  };

  signinSilentCallback = () => {
    return {};
  };
}
