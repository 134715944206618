import React, { useEffect, useState, useMemo } from 'react';
import UserTable from '../userlisttable/usertable';
import { getAdminUsers } from '../../api/users';
import { LoadingComponent } from '../../Loading';
import { User } from '../../types/user';

export type Status = 'PENDING_INVITE' | 'ACTIVE' | 'INACTIVE';

export type StatusFilter = Status | '*';

export interface AccountMembership {
  accountId: string;
  roles: string[];
}

export default function UserList() {
  const [users, updateUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, updateSearchText] = useState<string>('');
  const [statusFilter, updateStatusFilter] = useState<StatusFilter>('ACTIVE');

  const fetchUsers = async () => {
    let users: User[] = [];
    try {
      users = await getAdminUsers();
    } catch (e) {
      // not authorized to read users, set list to empty
      console.log('unable to fetch users', e);
    }
    updateUsers(users);
  };

  useEffect(() => {
    const setUsers = async () => {
      await fetchUsers();
      setLoading(false);
    };
    setUsers();
  }, []);

  const filteredUsers = useMemo(() => {
    const search = searchText.trim().toLowerCase();
    return users.filter((user) => {
      if (statusFilter !== '*' && statusFilter !== user.status) {
        return false;
      }

      return (
        !searchText ||
        user.familyName.toLowerCase().includes(search) ||
        user.givenName.toLowerCase().includes(search) ||
        user.id.toLowerCase().includes(search) ||
        user.email.toLowerCase().includes(search) ||
        user.phone.toLowerCase().includes(search)
      );
    });
  }, [searchText, statusFilter, users]);

  return (
    <div>
      <LoadingComponent isLoading={loading}>
        <UserTable
          users={filteredUsers}
          fetchUsers={fetchUsers}
          subTable={false}
          searchText={searchText}
          updateSearchText={updateSearchText}
          statusFilter={statusFilter}
          updateStatusFilter={updateStatusFilter}
        />
      </LoadingComponent>
    </div>
  );
}
