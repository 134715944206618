import JSZip from 'jszip';
import { getAuthToken, getBaseApiUrl } from './helpers';
import { KeyValue, Mapping } from '../types/mapping/';
import { toast } from 'react-toastify';

export const fetchSiteList = async (product: string) => {
  const url = `${getBaseApiUrl()}/v1/api/config/${product}/sites`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<string[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export async function fetchMappingsList(): Promise<{ mappingName: string; data: KeyValue[] }[]> {
  try {
    const url = `${getBaseApiUrl()}/v1/api/config/choice/mappings`;
    const response = await fetch(url, {
      headers: {
        Authorization: await getAuthToken(),
      },
    });
    if (response.ok) {
      const mappingsData: { [key: string]: string } = await response.json();
      const mappingsArray = Object.entries(mappingsData).map(([mappingName, dataString]) => ({
        mappingName,
        data: JSON.parse(dataString),
      }));
      return mappingsArray;
    } else if (response.status === 404) {
      return Promise.reject('Error 404');
    } else {
      return Promise.reject('Call Failed: ' + response.status);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchMappings(): Promise<Mapping[]> {
  const fetchedMappings = await fetchMappingsList();

  // transform fetched json mappings to Mapping interface
  const transformedMappings: Mapping[] = fetchedMappings.map((item) => {
    // extract mappingName and data from database item
    const { mappingName, data } = item;

    // return Mapping object
    return { mappingName, data };
  });

  return transformedMappings;
}

export async function saveMappings(mappings: Mapping[]): Promise<void> {
  console.log('mappings:');
  console.log(mappings);
  // convert mapping back to json for saving
  const mappedData: { [key: string]: string } = {};
  mappings.forEach((mapping) => {
    mappedData[mapping.mappingName] = JSON.stringify(mapping.data);
  });

  console.log('mappedData:');
  console.log(mappedData);
  const jsonData = JSON.stringify(mappedData);
  console.log('jsonData:');
  console.log(jsonData);

  const url = `${getBaseApiUrl()}/v1/api/config/choice/saveMappings`;
  console.log('posting....');
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
      'Content-Type': 'application/json',
    },
    body: jsonData,
  });
  if (response.ok) {
    return;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
}

export const exportConfig = async (product: string, site: string, key: string) => {
  const url = `${getBaseApiUrl()}/v1/api/config/${product}/export?&client=${site}&key=${key}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    const data = await response.text();
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${product}_${site}_${key}_config.zip`;
    link.click();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const importConfig = async (product: string, site: string, key: string, importFile: File) => {
  const url = `${getBaseApiUrl()}/v1/api/config/${product}/import?&client=${site}&key=${key}`;

  const zip = new JSZip();
  zip.file('importFile', importFile, { compression: 'DEFLATE' });
  const base64Data = await zip.generateAsync({ type: 'base64' });

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
      'Content-Type': 'application/zip',
    },
    body: base64Data,
  });

  if (response.ok) {
    return;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
